import { inject } from '@angular/core'
import { Observable } from 'rxjs'
import { MessageService } from './core/services/message.service'

export function toDate(str: string | undefined | null) {
    if (str) {
        return new Date(str)
    }
    return undefined
}
